export const hexToRgbArray = (hex: string) => {
  // Remove the # if present
  hex = hex.replace('#', '');

  // Check if it's a shorthand hex value (e.g., #f00)
  if (hex.length === 3) {
    hex = hex
      .split('')
      .map((char) => char + char)
      .join('');
  }

  // Parse the hex values into decimal
  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return [r, g, b];
};

export const hexToRgb = (hex: string) => {
  const rgbArr = hexToRgbArray(hex);
  return `rgb(${rgbArr.join(',')})`;
};

export const rgbWithOpacity = (color: string, opacity: number) => {
  const colorMatch = color.match(/rgb\(((\d+,?\s?)+)\)/);
  if (colorMatch?.[1]) {
    const colorArr = colorMatch[1].split(',');
    return `rgba(${colorArr.join(',')}, ${opacity})`;
  }
  return color;
};
