import { backendHost, isProdEnv, isTestEnv } from '../utils/constants';
import hyperFetch from '../utils/fetch';
import hardcodedNodes, { hardcodedSupplieds } from '../utils/hardcoded-node';
import { Instance } from '../utils/types';

export const fetchMarketplace = async () => {
  const response = await hyperFetch(`${backendHost}/v1/marketplace`, {
    method: 'POST',
    body: JSON.stringify({ filters: {} }),
  });
  return [
    ...hardcodedNodes,
    ...response.instances.map((i: Instance) => ({
      ...i,
      id: `${i.cluster_name}-${i.id}`,
      nodeName: i.id,
    })),
  ];
};

export const fetchRented = async () => {
  const response = await hyperFetch(`${backendHost}/v1/marketplace/instances`);
  return response.instances;
};

export const fetchSupplied = async () => {
  const response = await hyperFetch(
    `${backendHost}/v1/marketplace/instances/supplied`
  );
  if (!isProdEnv) {
    return response.nodes.length === 0 ? hardcodedSupplieds : response.nodes;
  }
  return response.nodes;
};

export const addNodePriceDb = async ({
  amount,
  clusterName,
  nodeName,
}: {
  amount: number;
  clusterName: string;
  nodeName: string;
}) => {
  const response = await hyperFetch(
    `${import.meta.env.VITE_BACKEND_HOST}/v1/marketplace/node/price`,
    {
      method: 'POST',
      body: JSON.stringify({
        amount,
        cluster_name: clusterName,
        node_name: nodeName,
      }),
    }
  );
  return response;
};

export const fetchSupplierInstructions = async () => {
  const fileUrl = `https://raw.githubusercontent.com/HyperbolicLabs/Hyper-dOS/${
    isTestEnv ? 'dev' : 'main'
  }/README.md`;

  const response = await fetch(fileUrl);
  const text = await response.text();
  return text;
};

export const terminateInstanceDb = (rentalId: string) =>
  hyperFetch(`${backendHost}/v1/marketplace/instances/terminate`, {
    method: 'POST',
    body: JSON.stringify({
      id: rentalId,
    }),
  });

export interface DockerImage {
  name: string;
  tag: string;
  port: number;
}

export const rentInstanceDb = ({
  nodeName,
  clusterName,
  image,
  gpuCount,
}: {
  nodeName: string;
  clusterName: string;
  image?: DockerImage;
  gpuCount?: number;
}) =>
  hyperFetch(`${backendHost}/v1/marketplace/instances/create`, {
    method: 'POST',
    body: JSON.stringify({
      cluster_name: clusterName,
      node_name: nodeName,
      image,
      ...(gpuCount && { gpu_count: gpuCount }),
    }),
  });
