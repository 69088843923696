import { ReactNode } from 'react';
import Card from '../common/Card';

const UsageChartCard = ({ children }: { children: ReactNode }) => (
  <Card
    noHover
    className="w-full bg-white border-theme-neutral-200 shadow-theme-02 p-8 flex flex-col gap-5"
  >
    {children}
  </Card>
);

export default UsageChartCard;
