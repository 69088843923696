import { InstanceRental, InstanceStatus } from '../../utils/types';
import { differenceInCalendarDays } from 'date-fns';
import { openModal } from '../../slices/modals';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '../../store';
import { ModalName } from '../modals';
import {
  calculateStorageCapacity,
  displayModelName,
  findGPUPriceInstance,
} from '../../utils/instances';
import InstanceCard from './InstanceCard';
import Badge from '../common/Badge';
import { useFeatureFlagEnabled, usePostHog } from 'posthog-js/react';
import CopyButton from '../common/CopyButton';
import { isMobile } from 'react-device-detect';
import { mobileStyles } from '../../utils/modals';
import twClassnames from '../../utils/classnames';
import InstanceAgeDiff from './InstanceAgeDiff';

const RentedInstanceCard = ({ rental }: { rental: InstanceRental }) => {
  const dispatch = useDispatch<AppDispatch>();
  const expandableCard = useFeatureFlagEnabled('expandable-instance-card');
  const posthog = usePostHog();

  const { start, instance, sshCommand, id } = rental;
  const { gpu, gpuCount, cpu, gpuRamGB, storage, ramCapacity } = instance;
  const storageCapacity = calculateStorageCapacity(
    (storage?.capacity / instance.gpus_total) * gpuCount
  );

  const { city } = instance?.location || {
    city: 'San Francisco',
  };

  const region = 'North America';
  const country = 'US';

  const { upload, download } = instance?.network || {};

  const endOfYear = new Date('12/31/2024');
  const max = differenceInCalendarDays(endOfYear, Date.now());

  const status = (instance.status as InstanceStatus) || InstanceStatus.busy;
  const displayedStatus =
    status === InstanceStatus.offline ? InstanceStatus.starting : status;
  const disabled = displayedStatus !== InstanceStatus.online;

  return (
    <InstanceCard
      id={instance.id}
      status={displayedStatus}
      gpuCount={gpuCount}
      gpuModelName={displayModelName(gpu?.model)}
      onClick={(e: any) => {
        e.preventDefault();
        e.stopPropagation();
        posthog?.capture('Rented Modal Opened', { rentalId: rental.id });
        dispatch(
          openModal({
            name: ModalName.RentedInstanceModal,
            props: {
              rentalId: rental.id,
            },
            styles: isMobile ? mobileStyles : {},
          })
        );
      }}
      gpuRamGB={gpuRamGB}
      storageCapacity={storageCapacity}
      cpuCores={cpu?.cores}
      ramGB={ramCapacity}
      location={`${country || city}${
        region || country ? `, ${region || country}` : ''
      }`}
      upload={upload}
      download={download}
      maxDays={max}
      endOfYear={endOfYear}
      gpuPrice={findGPUPriceInstance(instance)}
      pricePeriod={instance.pricing?.price?.period}
      actionButton={
        <>
          <InstanceAgeDiff
            start={start}
            className="text-xs text-theme-neutral-600"
          />
          <div className="flex">
            <CopyButton
              id={`rented-instances-${id}`}
              copyText={sshCommand}
              className="hover:text-theme-primary-500 hover:no-underline"
              size={16}
              text="SSH"
              copyClassName={twClassnames('text-theme-neutral-600', {
                'text-theme-neutral-400':
                  disabled || status === InstanceStatus.stopping,
              })}
              disabled={disabled || status === InstanceStatus.stopping}
            />
          </div>
        </>
      }
      expansion={
        expandableCard ? (
          <>
            <div className="flex gap-2 flex-1">
              <div>Provided By: {instance.owner}</div>
              <div>
                <Badge
                  text="verified"
                  className="text-xs px-1.5 py-0 rounded-[4px] bg-theme-primary-50"
                />
              </div>
            </div>
            <div className="flex flex-col flex-1">
              <div>CPU Model: {cpu?.model}</div>
              <div>Docker image: N/A</div>
            </div>
            <div className="flex flex-col flex-1">
              <div>Network Latency: N/A</div>
              <div>Interconnect Network: N/A</div>
            </div>
            <div className="w-40" />
          </>
        ) : null
      }
    />
  );
};

export default RentedInstanceCard;
