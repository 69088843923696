import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import {
  fetchMarketplaceInstances,
  getFormattedMarketplaceInstances,
  getMarketplaceInitialLoading,
  getMarketplaceStatus,
} from '../slices/instances';
import { AppDispatch } from '../store';
import { Instance, ResponseStatus } from '../utils/types';
import MarketplaceInstanceCard from '../components/Compute/MarketplaceInstanceCard';
import Button from '../components/common/Button';
import useInterval from '../hooks/useInterval';
import useInstanceFilters from '../hooks/useInstanceFilters';
import { openModal } from '../slices/modals';
import { ModalName } from '../components/modals';
import Closeable from '../components/common/Closeable';
import InstanceFilters from '../components/Compute/InstanceFilters';
import { useSearchParams } from 'react-router-dom';

const MarketplaceInstances = () => {
  const dispatch = useDispatch<AppDispatch>();
  const instances = useSelector(getFormattedMarketplaceInstances);
  const marketplaceStatus = useSelector(getMarketplaceStatus);
  const marketplaceLoading = marketplaceStatus === ResponseStatus.Loading;
  const marketplaceInitialLoading = useSelector(getMarketplaceInitialLoading);
  const [searchParams] = useSearchParams();
  const defaultFilter = Object.fromEntries(searchParams.entries());
  const { sortedInstances, ...filters } = useInstanceFilters(
    instances,
    undefined,
    {
      sortFn: (item) => item.reserved,
      sortOrder: 'asc',
      prioritizeOverPrice: true,
    },
    defaultFilter
  );

  useEffect(() => {
    dispatch(fetchMarketplaceInstances());
  }, [dispatch]);

  useInterval(() => {
    if (!marketplaceLoading) {
      dispatch(fetchMarketplaceInstances());
    }
  }, 5000);

  // todo: maybe find a better way to handle union types
  const unavailable =
    !sortedInstances?.filter(
      (ir: any) => !ir.reserved && !ir.instance?.reserved
    ).length && !marketplaceInitialLoading;

  return (
    <div className="flex flex-col w-full gap-6">
      <InstanceFilters {...filters} theme="marketplace" />
      {!!unavailable && (
        <Closeable className="bg-theme-primary-50 py-3 px-5 w-full rounded-lg text-theme-neutral-600 text-sm">
          {/* TODO: detect filters and add them to notify */}
          <div className="flex flex-wrap items-center gap-1">
            <div>
              We are sorry that we don't have any available GPUs
              {instances?.length > 0 && ` that match your filters`}.
            </div>
            <Button
              variant="link"
              onClick={() => dispatch(openModal({ name: ModalName.NotifyMe }))}
              className="text-sm"
            >
              Notify me when available.
            </Button>
          </div>
        </Closeable>
      )}
      <div className="flex flex-col gap-4">
        {/* {!sortedInstances.length && !!user && (
          <Card noHover className="select-none">
            <div>
              <span>No available instances match your filters</span>
              <Button
                onClick={() => dispatch(fetchMarketplaceInstances())}
                className="ml-4"
                isLoading={status === ResponseStatus.Loading}
              >
                Retry
              </Button>
            </div>
          </Card>
        )} */}
        {/* {loading && (
          <div className="ml-4">
            <LoadingSpinner className="w-4 h-4 mr-2" />
          </div>
        )} */}
        {marketplaceInitialLoading ? (
          <div className="mb-6">
            <div className="animate-pulse">
              <div className="flex flex-col gap-4">
                <div className="h-[106px] bg-theme-neutral-200 rounded-xl w-full" />
                <div className="h-[106px] bg-theme-neutral-200 rounded-xl w-full" />
                <div className="h-[106px] bg-theme-neutral-200 rounded-xl w-full" />
              </div>
            </div>
          </div>
        ) : (
          (sortedInstances as Instance[]).map((instance) => (
            <MarketplaceInstanceCard key={instance.id} instance={instance} />
          ))
        )}
      </div>
    </div>
  );
};

export default MarketplaceInstances;
