import UsageChart from './UsageChart';
import UsageChartCard from './UsageChartCard';
import ChartPill from './ChartPill';
import { useState } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import UsageTable from './UsageTable';
import useModelUsage from '../../hooks/useModelUsage';
import { getChartColor } from '../../utils/chart';

const columnHelper = createColumnHelper<any>();

const TextCharts = () => {
  const type = 'llm';
  const [hiddenModels, setHiddenModels] = useState<string[]>([]);
  const { mapModelToName, modelsWithUsage, chartData, tableData } =
    useModelUsage(type, (item) => item.prompt_tokens + item.completion_tokens, [
      'prompt_tokens',
      'completion_tokens',
    ]);
  const columns = [
    columnHelper.accessor('name', {
      header: 'Model',
      cell: (info) => mapModelToName(info.getValue()),
    }),
    columnHelper.accessor('prompt_tokens', {
      header: 'Input Tokens',
    }),
    columnHelper.accessor('completion_tokens', {
      header: 'Output Tokens',
    }),
    columnHelper.accessor((row) => row.prompt_tokens + row.completion_tokens, {
      header: 'Total Tokens',
    }),
  ];

  return (
    <UsageChartCard>
      <div className="flex flex-col gap-2">
        <div className="text-black text-lg font-semibold">
          LLM Token Generation
        </div>
        <div className="text-sm">
          Keep track of the number of tokens (input + output) processed by each
          model overtime.
        </div>
      </div>
      <div className="flex gap-3">
        {modelsWithUsage.map((model, i) => (
          <ChartPill
            key={`${model}-pill`}
            color={getChartColor(type, i)}
            text={mapModelToName(model)}
            onClick={() => {
              const hiddenIndex = hiddenModels.indexOf(model);
              if (hiddenIndex > -1) {
                return setHiddenModels((hidden) =>
                  hidden
                    .slice(0, hiddenIndex)
                    .concat(hidden.slice(hiddenIndex + 1))
                );
              }
              return setHiddenModels((hidden) => hidden.concat(model));
            }}
            selected={hiddenModels.includes(model)}
          />
        ))}
      </div>
      <div className="text-sm font-semibold text-black">Top Tokens</div>
      <UsageChart
        type={type}
        chartData={chartData}
        modelsWithUsage={modelsWithUsage}
        hiddenModels={hiddenModels}
        mapModelToName={mapModelToName}
      />
      <UsageTable tableData={tableData} columns={columns} />
    </UsageChartCard>
  );
};

export default TextCharts;
