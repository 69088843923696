import range from 'lodash/range';

const hardcodedNode = {
  id: 'muffled-coconut-wombat-ubuntu-12',
  status: 'node_ready',
  hardware: {
    cpus: [
      {
        hardware_type: 'cpu',
        model: 'Intel-6-143',
        cores: 176,
        virtual_cores: 176,
      },
    ],
    gpus: [
      {
        hardware_type: 'gpu',
        model: 'NVIDIA-H100-80GB-HBM3',
        clock_speed: 1000,
        compute_power: 1000,
        ram: 81559,
        interface: 'PCIeX16',
      },
      {
        hardware_type: 'gpu',
        model: 'NVIDIA-H100-80GB-HBM3',
        clock_speed: 1000,
        compute_power: 1000,
        ram: 81559,
        interface: 'PCIeX16',
      },
      {
        hardware_type: 'gpu',
        model: 'NVIDIA-H100-80GB-HBM3',
        clock_speed: 1000,
        compute_power: 1000,
        ram: 81559,
        interface: 'PCIeX16',
      },
      {
        hardware_type: 'gpu',
        model: 'NVIDIA-H100-80GB-HBM3',
        clock_speed: 1000,
        compute_power: 1000,
        ram: 81559,
        interface: 'PCIeX16',
      },
      {
        hardware_type: 'gpu',
        model: 'NVIDIA-H100-80GB-HBM3',
        clock_speed: 1000,
        compute_power: 1000,
        ram: 81559,
        interface: 'PCIeX16',
      },
      {
        hardware_type: 'gpu',
        model: 'NVIDIA-H100-80GB-HBM3',
        clock_speed: 1000,
        compute_power: 1000,
        ram: 81559,
        interface: 'PCIeX16',
      },
      {
        hardware_type: 'gpu',
        model: 'NVIDIA-H100-80GB-HBM3',
        clock_speed: 1000,
        compute_power: 1000,
        ram: 81559,
        interface: 'PCIeX16',
      },
      {
        hardware_type: 'gpu',
        model: 'NVIDIA-H100-80GB-HBM3',
        clock_speed: 1000,
        compute_power: 1000,
        ram: 81559,
        interface: 'PCIeX16',
      },
    ],
    storage: [
      {
        hardware_type: 'storage',
        capacity: 266,
      },
    ],
    ram: [
      {
        hardware_type: 'ram',
        capacity: 1014,
      },
    ],
  },
  location: {
    region: 'region-1',
  },
  network: {},
  supplier_id: 'megqcfuuqho6qbxzmwsasb1kfb23',
  reserved: true,
  pricing: {
    price: {
      amount: 99,
      period: 'hourly',
      agent: 'platform',
    },
  },
  gpus_total: 8,
  gpus_reserved: 8,
  cluster_name: 'muffled-coconut-wombat',
};

const hardcodedNodes = range(4).map((x) => ({
  ...hardcodedNode,
  id: `${hardcodedNode.id}-${x}`,
}));

const hardcodedSupplied = {
  id: 'ip-172-31-76-108',
  status: 'node_ready',
  hardware: {
    cpus: [
      {
        hardware_type: 'cpu',
        model: 'Intel-6-85',
        cores: 4,
        virtual_cores: 4,
      },
    ],
    gpus: [
      {
        hardware_type: 'gpu',
        model: 'Tesla-T4',
        clock_speed: 1000,
        compute_power: 1000,
        ram: 15360,
        interface: 'PCIeX16',
      },
    ],
    storage: [
      {
        hardware_type: 'storage',
        capacity: 155,
      },
    ],
    ram: [
      {
        hardware_type: 'ram',
        capacity: 16,
      },
    ],
  },
  location: {
    region: 'region-1',
  },
  network: {},
  gpus_total: 2,
  gpus_reserved: 1,
  instances: [],
  pricing: {
    price: {
      amount: 17,
      period: 'hourly',
      agent: 'platform',
    },
  },
  cluster_name: 'tremendous-blackberry-octopus',
};

export const hardcodedSupplieds = range(4).map((x) => ({
  ...hardcodedSupplied,
  id: `${hardcodedNode.id}-${x}`,
}));

export default hardcodedNodes;
