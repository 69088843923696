import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import {
  AudioInputParameters,
  ImageInputParameters,
  Model,
  TextInputParameters,
} from '../../utils/types';
import {
  FormatFn,
  formatAudioCurlRequest,
  formatAudioPythonRequest,
  formatAudioTsRequest,
  formatBaseCurlRequest,
  formatBasePythonRequest,
  formatBaseTsRequest,
  formatCurlRequest,
  formatImageCurlRequest,
  formatImagePythonRequest,
  formatImageTsRequest,
  formatPythonRequest,
  formatTsRequest,
  formatVisionTsRequest,
  formatVisionPythonRequest,
  formatGradioRequest,
} from '../../utils/models';
import CopyButton from '../common/CopyButton';
import { useSelector } from 'react-redux';
import { getUserInfo } from '../../slices/auth';
import { isMobile } from 'react-device-detect';
import {
  getAIResponse,
  getAudioValue,
  getChatValue,
  getMessages,
} from '../../slices/chat';
import { vlmDefaultImageURL } from '../../utils/constants';
import HyperLink from '../common/HyperLink';
import ShareIcon from '../common/icons/ShareIcon';
import twClassnames from '../../utils/classnames';

const mapLangToVisionFormat: { [key: string]: FormatFn } = {
  python: formatVisionPythonRequest,
  ts: formatVisionTsRequest,
  curl: formatCurlRequest, // curl is hidden by default for this type of model
};

const mapBaseToFormat: { [key: string]: any } = {
  python: formatBasePythonRequest,
  ts: formatBaseTsRequest,
  curl: formatBaseCurlRequest,
};
const mapLangToFormat: { [key: string]: FormatFn } = {
  python: formatPythonRequest,
  ts: formatTsRequest,
  curl: formatCurlRequest,
};
const mapLangToImageFormat: { [key: string]: any } = {
  python: formatImagePythonRequest,
  ts: formatImageTsRequest,
  curl: formatImageCurlRequest,
};
const mapLangToAudioFormat: { [key: string]: any } = {
  python: formatAudioPythonRequest,
  ts: formatAudioTsRequest,
  curl: formatAudioCurlRequest,
};
const mapLangToFormatFn: { [key: string]: any } = {
  vlm: mapLangToVisionFormat,
  llm: mapLangToFormat,
  image: mapLangToImageFormat,
  audio: mapLangToAudioFormat,
};
const defaultApiKey = '$HYPERBOLIC_API_KEY';
const defaultText = 'What can I do in LA?';
const defaultMessages = [{ role: 'user', content: defaultText }];
const defaultImageText = 'What is this image?';

const defaultImageMessages = [
  {
    role: 'user',
    content: defaultImageText,
    image: vlmDefaultImageURL,
  },
];
const defaultAudioText =
  'Los Angeles! The City of Angels is a treasure trove of exciting experiences, iconic landmarks, and endless entertainment options.';

const createSpacesUrl = (code: string) => {
  const base_URL = 'https://huggingface.co/new-space';
  const params = new URLSearchParams({
    name: 'new-space',
    sdk: 'gradio',
  });
  const encoded_content = code.trim();
  params.append('files[0][path]', 'app.py');
  params.append('files[0][content]', encoded_content);
  params.append('files[1][path]', 'requirements.txt');
  params.append('files[1][content]', 'hyperbolic-gradio');
  return `${base_URL}?${params.toString()}`;
};

const ModelCodeSnippet = ({
  id,
  model,
  textInputParameters,
  imageInputParameters,
  audioInputParameters,
  language,
  type = 'llm',
  liveUpdates = false,
  modelName = '',
  isGradio = false,
}: {
  id: string;
  model: Model | undefined;
  textInputParameters: TextInputParameters<number>;
  imageInputParameters: ImageInputParameters<number, boolean>;
  audioInputParameters: AudioInputParameters<number>;
  language: string;
  type?: string;
  liveUpdates?: boolean;
  modelName?: string;
  isGradio?: boolean;
}) => {
  const userInfo = useSelector(getUserInfo);
  const chatMessages = useSelector(getMessages);
  const chatValue = useSelector(getChatValue);
  const audioValue = useSelector(getAudioValue);
  const isVLM = type === 'vlm';
  const isBase = model?.subType === 'base';
  let formatFn =
    mapLangToFormatFn[type][language] || mapLangToFormatFn['llm'][language];
  if (isBase) {
    formatFn = mapBaseToFormat[language];
  } else if (isGradio) {
    formatFn = formatGradioRequest;
  }
  const apiKey = isMobile ? defaultApiKey : userInfo?.api_key || defaultApiKey;
  const highlighterLang = language === 'curl' ? 'shell' : language;
  const aiResponse = useSelector(getAIResponse);
  const messages = [];
  if (textInputParameters.systemPrompt) {
    messages.unshift({
      role: 'system',
      content: textInputParameters.systemPrompt,
    });
  }
  const formattedHistory = chatMessages.map((msg) => ({
    role: msg.from === 'ai' ? 'assistant' : 'user',
    content: msg.text,
    ...((msg.image || isVLM) && {
      image: 'image',
      image_process_mode: 'default',
    }),
  }));
  const inputPrompt = chatValue || isVLM ? defaultImageText : defaultText || '';
  const messagesWithHistory: {
    role: string;
    content: string;
    image?: string;
  }[] = messages
    .concat(formattedHistory)
    .concat(
      formattedHistory.length === 0
        ? [
            {
              role: 'user',
              content: inputPrompt,
            },
          ]
        : []
    )
    .concat(
      aiResponse.length > 0
        ? [{ role: 'assistant', content: aiResponse.join('') }]
        : []
    );
  const audioText = liveUpdates
    ? audioValue || defaultAudioText
    : defaultAudioText;
  const messagesArr = isVLM // liveUpdates not supported for VLMs yet
    ? defaultImageMessages
    : liveUpdates
    ? messagesWithHistory
    : defaultMessages;

  let codeString = formatFn?.(
    modelName,
    model?.url || '',
    textInputParameters,
    apiKey,
    messagesArr
  );
  if (type === 'image') {
    codeString = formatFn?.(
      modelName,
      model?.url || '',
      imageInputParameters,
      apiKey
    );
  } else if (type === 'audio') {
    codeString = formatFn?.(
      modelName,
      model?.url || '',
      audioInputParameters,
      apiKey,
      audioText
    );
  } else if (isBase) {
    codeString = formatFn?.(
      modelName,
      model?.url || '',
      textInputParameters,
      apiKey,
      inputPrompt,
      isVLM ? '[image]' : undefined
    );
  }

  const spacesUrl = createSpacesUrl(codeString);
  return (
    <>
      <div className="relative mt-2 md:mt-6">
        <div className={twClassnames('flex items-center gap-2 absolute top-8 right-6', { 'top-4 justify-center w-full md:w-auto right-auto md:right-6': isGradio })}>
          {isGradio && (
            <HyperLink
              variant="ghost"
              href={spacesUrl}
              target="_blank"
              rel="noreferrer"
              className="flex items-center gap-2"
            >
              <ShareIcon size={20} />
              Deploy on HF Spaces
            </HyperLink>
          )}
          <CopyButton
            id={id}
            className="text-theme-neutral-600 hover:text-theme-primary-600"
            copyText={codeString}
          />
        </div>
      </div>
      <SyntaxHighlighter
        PreTag="div"
        language={highlighterLang}
        style={oneLight}
        wrapLines
        wrapLongLines
        className="flex text-sm rounded-lg max-w-xs md:max-w-none border"
        customStyle={{ padding: '30px', overflow: 'unset', ...(isGradio && { paddingTop: 50 }) }}
      >
        {codeString}
      </SyntaxHighlighter>
    </>
  );
};

export default ModelCodeSnippet;
