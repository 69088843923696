import { useMemo, useReducer } from 'react';
import {
  Instance,
  InstanceHardwareGPU,
  InstanceHardwareStorage,
  InstanceHardwareType,
  InstanceRental,
  Option,
} from '../utils/types';
import union from 'lodash/union';
import { displayModelName, findGPUPriceInstance } from '../utils/instances';
import range from 'lodash/range';
import orderBy from 'lodash/orderBy';
import { DateRange } from 'react-day-picker';
import identity from 'lodash/identity';

const quantities = range(1, 9);
export const quantityOptions: Option[] = [
  ...quantities.map((q) => ({ value: String(q), label: `${q} X` })),
  { value: '8+', label: '8 X+' },
];

const storageOptions = [
  {
    value: '0-500',
    label: '0 - 500GB',
  },
  {
    value: '500-1000',
    label: '500GB - 1TB',
  },
  {
    value: '1000-1500',
    label: '1TB - 1.5TB',
  },
  {
    value: '1500-2000',
    label: '1.5TB - 2TB',
  },
  { value: '2000', label: '2TB+' },
];

export type PriceFilterOption = 'any' | 'asc' | 'desc';
export const priceOptions = [
  { value: 'asc', label: 'From Low to High' },
  { value: 'desc', label: 'From High to Low' },
];

const getStorage = (instance: Instance) =>
  (instance.hardwareArr || []).filter(
    (h) => h.hardware_type === InstanceHardwareType.Storage
  ) as InstanceHardwareStorage[];

const getStorageCapacity = (instance: Instance) =>
  getStorage(instance)?.[0]?.capacity || 1024;

type InstanceSortOptions = {
  sortFn?: (item: any) => any;
  sortOrder: 'desc' | 'asc';
  prioritizeOverPrice: boolean;
};

// const defaultSort = (instance: Instance | InstanceRental) => instance.gpu?.model.includes('H100') && findGPUPriceInstance(mapFn(instance.price));

const sortInstances = (
  filteredInstances: (Instance | InstanceRental)[],
  priceFilter: 'any' | 'asc' | 'desc',
  mapFn: (item: any) => Instance,
  sortOpts: InstanceSortOptions = {
    sortFn: undefined,
    sortOrder: 'asc',
    prioritizeOverPrice: false,
  }
) => {
  const defaultSort = (instance: Instance | InstanceRental) =>
    mapFn(instance).gpu?.model.includes('H100')
      ? findGPUPriceInstance(mapFn(instance))
      : 100;
  const { sortFn, sortOrder, prioritizeOverPrice } = sortOpts;
  let sortedInstances = filteredInstances;
  if (priceFilter !== 'any') {
    sortedInstances = orderBy(
      filteredInstances,
      [(instance) => findGPUPriceInstance(mapFn(instance)), defaultSort],
      [priceFilter, 'asc']
    );
  }
  if (sortFn) {
    if (priceFilter === 'any') {
      sortedInstances = orderBy(
        filteredInstances,
        [sortFn, defaultSort],
        [sortOrder, 'asc']
      );
    }
    if (priceFilter !== 'any' && prioritizeOverPrice) {
      sortedInstances = orderBy(
        filteredInstances,
        [
          sortFn,
          (instance) => findGPUPriceInstance(mapFn(instance)),
          defaultSort,
        ],
        [sortOrder, priceFilter, 'asc']
      );
    } else if (priceFilter !== 'any') {
      sortedInstances = orderBy(
        filteredInstances,
        [
          (instance) => findGPUPriceInstance(mapFn(instance)),
          sortFn,
          defaultSort,
        ],
        [priceFilter, sortOrder, 'asc']
      );
    }
  }
  return sortedInstances;
};

interface InstanceFilters {
  gpuFilter: string;
  quantityFilter: string;
  storageFilter: string;
  locationFilter: string;
  priceFilter: 'any' | 'asc' | 'desc';
  daysFilter: DateRange | undefined;
}

const initialState: InstanceFilters = {
  gpuFilter: 'any',
  quantityFilter: 'any',
  storageFilter: 'any',
  locationFilter: 'any',
  priceFilter: 'any',
  daysFilter: undefined,
};

const instanceFiltersReducer = (
  state = initialState,
  action: { type: string; payload: { id: string; value: any } }
) => {
  switch (action.type) {
    case 'change_filter': {
      const { id, value } = action.payload;
      return { ...state, [id]: value };
    }
    default:
      return state;
  }
};

const useInstanceFilters = (
  instancesData: Instance[] | InstanceRental[],
  mapFn: (item: any) => Instance = identity,
  sortOpts?: InstanceSortOptions,
  defaultState = {}
) => {
  const [filtersState, filtersDispatch] = useReducer(instanceFiltersReducer, {
    ...initialState,
    ...defaultState,
  });
  const instances: Instance[] = instancesData.map(mapFn);
  const { gpuFilter, quantityFilter, storageFilter, priceFilter } =
    filtersState;

  const handleFilterChange = ({ id, value }: { id: string; value: any }) => {
    filtersDispatch({ type: 'change_filter', payload: { id, value } });
  };

  const allLocations = useMemo(() => {
    return [];
    // return instances.map((instance) => instance.location.region);
  }, []);

  const allGPUs = useMemo(() => {
    return instances.reduce<InstanceHardwareGPU[]>(
      (a, c) => a.concat([c.gpu]),
      []
    );
  }, [instances]);

  const allStorage = useMemo(() => {
    return instances.reduce<InstanceHardwareStorage[]>(
      (a, c) => a.concat([c.storage]),
      []
    );
  }, [instances]);

  const locationOptions = union(allLocations).map((loc) => ({
    value: loc,
    label: loc,
  }));

  // todo: we are totally assuming that an instance will have all GPUs be of the same type!
  const gpuOptions = union(
    [...new Set([...allGPUs.map((gpu) => displayModelName(gpu?.model))])].map(
      (model) => ({
        value: model,
        label: model,
      })
    )
  );

  // const availableQuantity = union(
  //   instances.map((instance) =>
  //     instance.gpuCount > 8 ? '8+' : String(instance.gpuCount)
  //   )
  // );

  // const filteredQuantityOptions = quantityOptions.filter((type) =>
  //   availableQuantity.includes(type.value)
  // );

  const availableStorage = union(
    allStorage.map((instance) => instance?.capacity)
  );

  const filteredStorageOptions = storageOptions.filter((opt) => {
    const [min, max] = opt.value.split('-');
    return availableStorage.find(
      (storage) => storage >= Number(min) && storage < Number(max)
    );
  });

  const filteredInstances = instancesData.filter(
    (instanceData: Instance | InstanceRental) => {
      const instance = mapFn(instanceData);
      // gpu count
      const quantity = parseInt(quantityFilter);
      const instanceGPUCount = instance.gpuCount;
      const instanceStorageCapacity = getStorageCapacity(instance);
      if (quantityFilter === '8+') {
        if (instanceGPUCount <= 8) {
          return false;
        }
      } else if (
        !Number.isNaN(quantity) &&
        quantity <= 8 &&
        quantity > instanceGPUCount
      ) {
        return false;
      }

      // gpu type
      if (gpuFilter !== 'any') {
        if (displayModelName(instance.gpu?.model) !== gpuFilter) {
          return false;
        }
      }

      const storageCapacity = instanceStorageCapacity;
      if (storageFilter !== 'any') {
        if (storageFilter === '0-500') {
          if (storageCapacity > 500) return false;
        } else if (storageFilter === '500-1000') {
          if (storageCapacity < 500 || storageCapacity >= 1000) return false;
        } else if (storageFilter === '1000-1500') {
          if (storageCapacity < 1000 || storageCapacity >= 1500) return false;
        } else if (storageFilter === '1500-2000') {
          if (storageCapacity < 1500 || storageCapacity >= 2000) return false;
        } else {
          if (storageCapacity < 2000) return false;
        }
      }

      // todo: implement date filter
      return true;
    }
  );

  const sortedInstances = sortInstances(
    filteredInstances,
    priceFilter,
    mapFn,
    sortOpts
  );

  return {
    filteredInstances,
    filteredStorageOptions,
    locationOptions,
    gpuOptions,
    sortedInstances,
    handleFilterChange,
    ...filtersState,
  };
};

export default useInstanceFilters;
