import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IoDocumentTextOutline, IoImageOutline } from 'react-icons/io5';
import { AppDispatch } from '../store';
import {
  fetchModels,
  getModelsInitialLoading,
  getSortedModels,
} from '../slices/models';
import ModelCard from '../components/Models/ModelCard';
import { Tab, TabList, TabPanel, Tabs } from '../components/common/Tabs';
import { AiOutlineAudio, AiOutlineDiscord } from 'react-icons/ai';
import NotificationIcon from '../components/common/icons/NotificationIcon';
import Banner from '../components/common/Banner';
import HyperLink from '../components/common/HyperLink';
import { discordLink, salesLink, supportEmail } from '../utils/constants';
import Layout from '../components/common/Layout';
import squigglyHalfCircle from '../assets/squiggly-half-circle.png';
import LinkBlock from '../components/common/LinkBlock';
import ChartBreakoutIcon from '../components/common/icons/ChartBreakoutIcon';
import { MdOutlineAlternateEmail } from 'react-icons/md';

const ModelsSkeleton = () => (
  <>
    <div className="animate-pulse">
      <div className="h-[134px] bg-theme-neutral-200 rounded-xl w-full" />
    </div>
    <div className="animate-pulse">
      <div className="h-[134px] bg-theme-neutral-200 rounded-xl w-full" />
    </div>
    <div className="animate-pulse">
      <div className="h-[134px] bg-theme-neutral-200 rounded-xl w-full" />
    </div>
  </>
);

const Models = () => {
  const dispatch = useDispatch<AppDispatch>();
  const models = useSelector(getSortedModels);
  useEffect(() => {
    dispatch(fetchModels());
  }, [dispatch]);
  const initialLoading = useSelector(getModelsInitialLoading);
  const textModels = models.filter(
    (model) => model.type === 'llm' && model.subType !== 'base'
  );
  // TODO: make base models a completely different model type
  const baseModels = models.filter(
    (model) =>
      (model.type === 'llm' || model.type === 'vlm') && model.subType === 'base'
  );
  const imageModels = models.filter((model) => model.type === 'image');
  const audioModels = models.filter((model) => model.type === 'audio');
  const vlmModels = models.filter(
    (model) => model.type === 'vlm' && model.subType !== 'base'
  );
  const modelSections = [
    {
      headerTitle: 'Text',
      title: 'Text Generation',
      data: textModels,
      Icon: IoDocumentTextOutline,
    },
    {
      headerTitle: 'Image',
      title: 'Image Generation',
      data: imageModels,
      Icon: IoImageOutline,
    },
    {
      headerTitle: 'VLM',
      title: 'Vision Language Models',
      data: vlmModels,
      Icon: NotificationIcon,
    },
    {
      headerTitle: 'Base',
      title: 'Base Models',
      data: baseModels,
      Icon: NotificationIcon,
    },
    {
      headerTitle: 'Audio',
      title: 'Audio Generation',
      data: audioModels,
      Icon: AiOutlineAudio,
    },
  ];
  return (
    <Layout>
      <Banner
        storageKey="hostedModelsClicked"
        className="px-6 md:px-10 gap-2 md:gap-20 justify-between md:justify-center"
      >
        <div>
          Interested in hosting your own model? Contact our team at&nbsp;
          <HyperLink href={`mailto:${supportEmail}`}>{supportEmail}</HyperLink>
          &nbsp; or reach out in&nbsp;
          <HyperLink href={discordLink}>Discord</HyperLink>!
        </div>
      </Banner>
      <Tabs>
        <div className="sticky top-[88px] md:top-[60px] bg-white z-[5] pb-1">
          <h2>Models</h2>
          <TabList>
            <Tab>All</Tab>
            {modelSections.map((section) => (
              <Tab>{section.headerTitle}</Tab>
            ))}
            <Tab>Dedicated Host</Tab>
          </TabList>
        </div>

        <div className="mb-8">
          <TabPanel>
            {modelSections.map((section) => (
              <>
                <div className="flex items-center text-theme-neutral-700 mt-6 mb-5 text-sm">
                  <section.Icon className="mr-3" size={16} />
                  <div>{section.title}</div>
                </div>
                <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 gap-4">
                  {initialLoading ? (
                    <ModelsSkeleton />
                  ) : section.data.length > 0 ? (
                    section.data.map((model) => (
                      <ModelCard key={model.slug} {...model} />
                    ))
                  ) : (
                    <div>No {section.headerTitle} models yet</div>
                  )}
                </div>
              </>
            ))}
          </TabPanel>
          {modelSections.map((section) => (
            <TabPanel>
              <div className="grid grid-cols-1 mt-4 lg:grid-cols-2 2xl:grid-cols-3 gap-4">
                {section.data.map((model) => (
                  <ModelCard key={model.slug} {...model} />
                ))}
              </div>
            </TabPanel>
          ))}
          <TabPanel>
            <div className="flex flex-col gap-10 items-center justify-center mt-8">
              <h3 className="mb-0">
                Dedicated Endpoints for&nbsp;
                <span className="text-theme-primary-600">Any Model</span>
              </h3>
              <div className="text-center">
                Looking to scale your business without the hassle of shared
                resources?
                <br />
                You can choose any kind of model - open-source, fine-tuned, or
                even models of your own.
                <br />
                We will try our best to offer you the most competitive pricing.
                Let's talk.
              </div>
              <div className="flex flex-wrap gap-5">
                <LinkBlock
                  href={salesLink}
                  text="Book a Demo"
                  icon={
                    <ChartBreakoutIcon className="text-theme-primary-600" />
                  }
                />
                <LinkBlock
                  href={`mailto:${supportEmail}`}
                  text="Email"
                  icon={
                    <MdOutlineAlternateEmail
                      size={28}
                      className="text-theme-primary-600"
                    />
                  }
                />
                <LinkBlock
                  href={discordLink}
                  text="Join Discord"
                  icon={
                    <AiOutlineDiscord
                      size={28}
                      className="text-theme-primary-600"
                    />
                  }
                />
              </div>
              <img src={squigglyHalfCircle} style={{ width: 817 }} />
            </div>
          </TabPanel>
        </div>
      </Tabs>
    </Layout>
  );
};

export default Models;
