import { hexWithOpacity } from '../../utils/chart';
import twClassnames from '../../utils/classnames';
import Button from '../common/Button';
import LegendIcon from '../common/icons/LegendIcon';

const ChartPill = ({
  color,
  text,
  onClick,
  selected,
}: {
  color: string;
  text: string;
  onClick: any;
  selected: boolean;
}) => (
  <Button
    variant="outline"
    className={twClassnames(`flex items-center gap-2 bg-transparent`, {
      'border-theme-neutral-400 text-theme-neutral-400 opacity-30': selected,
    })}
    style={{
      color,
      borderColor: color,
      backgroundColor: hexWithOpacity(color, 0.1),
    }}
    onClick={onClick}
  >
    <LegendIcon color={color} />
    {text}
  </Button>
);

export default ChartPill;
