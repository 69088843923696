import AddFundsModal from './AddFundsModal';
import ContactSupplierModal from './ContactSupplierModal';
import ForgotPasswordModal from './ForgotPasswordModal';
import GeneratedImageModal from './GeneratedImageModal';
import InputParametersModal from './InputParametersModal';
import RequestGPUModal from './RequestGPUModal';
import AuthModal from './AuthModal';
import SupplyGPUModal from './SupplyGPUModal';
import ConfirmRentModal from './ConfirmRentModal';
import RentedInstanceModal from './RentedInstanceModal';
import SuppliedInstanceModal from './SuppliedInstanceModal';
import ConfirmDeleteGPUModal from './ConfirmDeleteGPUModal';
import GPURequestModal from './GPURequestModal';
import OnboardingModal from './OnboardingModal';
import NotifyMeModal from './NotifyMeModal';
import ResetPublicKeyModal from './ResetPublicKeyModal';
import ResetApiKeyModal from './ResetApiKeyModal';
import SetNodePriceModal from './SetNodePriceModal';
import FreeCreditsModal from './FreeCreditsModal';
import LowBalanceModal from './LowBalanceModal';
import NewGPUsModal from './NewGPUsModal';

export enum ModalName {
  RequestGPUModal = 'RequestGPUModal',
  SupplyGPUModal = 'SupplyGPUModal',
  ContactSupplierModal = 'ContactSupplierModal',
  InputParametersModal = 'InputParametersModal',
  AuthModal = 'AuthModal',
  GeneratedImageModal = 'GeneratedImageModal',
  ForgotPasswordModal = 'ForgotPasswordModal',
  AddFundsModal = 'AddFundsModal',
  ConfirmRentModal = 'ConfirmRentModal',
  RentedInstanceModal = 'RentedInstanceModal',
  SuppliedInstanceModal = 'SuppliedInstanceModal',
  ConfirmDeleteGPUModal = 'ConfirmDeleteGPUModal',
  GPURequestModal = 'GPURequestModal',
  OnboardingModal = 'OnboardingModal',
  NotifyMe = 'NotifyMeModal',
  ResetPublicKey = 'ResetPublicKeyModal',
  ResetApiKey = 'ResetApiKeyModal',
  SetNodePrice = 'SetNodePriceModal',
  FreeCredits = 'FreeCreditsModal',
  LowBalance = 'LowBalanceModal',
  NewGPUs = 'NewGPUsModal',
}

export const mapStrToModalName: { [key: string]: ModalName } = {
  NotifyMeModal: ModalName.NotifyMe,
  GPURequestModal: ModalName.GPURequestModal,
};

export default {
  [ModalName.RequestGPUModal]: RequestGPUModal,
  [ModalName.SupplyGPUModal]: SupplyGPUModal,
  [ModalName.ContactSupplierModal]: ContactSupplierModal,
  [ModalName.InputParametersModal]: InputParametersModal,
  [ModalName.AuthModal]: AuthModal,
  [ModalName.GeneratedImageModal]: GeneratedImageModal,
  [ModalName.ForgotPasswordModal]: ForgotPasswordModal,
  [ModalName.AddFundsModal]: AddFundsModal,
  [ModalName.ConfirmRentModal]: ConfirmRentModal,
  [ModalName.RentedInstanceModal]: RentedInstanceModal,
  [ModalName.SuppliedInstanceModal]: SuppliedInstanceModal,
  [ModalName.ConfirmDeleteGPUModal]: ConfirmDeleteGPUModal,
  [ModalName.GPURequestModal]: GPURequestModal,
  [ModalName.OnboardingModal]: OnboardingModal,
  [ModalName.NotifyMe]: NotifyMeModal,
  [ModalName.ResetPublicKey]: ResetPublicKeyModal,
  [ModalName.ResetApiKey]: ResetApiKeyModal,
  [ModalName.SetNodePrice]: SetNodePriceModal,
  [ModalName.FreeCredits]: FreeCreditsModal,
  [ModalName.LowBalance]: LowBalanceModal,
  [ModalName.NewGPUs]: NewGPUsModal,
};
