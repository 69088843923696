const ShareIcon = ({
  color = 'currentColor',
  size = 24,
  ...otherProps
}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...otherProps}
  >
    <g id="share-05">
      <g id="Solid">
        <path
          d="M8.2587 2H10.5C11.0523 2 11.5 2.44772 11.5 3C11.5 3.55229 11.0523 4 10.5 4H8.3C7.44342 4 6.86113 4.00078 6.41104 4.03755C5.97262 4.07337 5.74842 4.1383 5.59202 4.21799C5.2157 4.40973 4.90973 4.7157 4.71799 5.09202C4.6383 5.24842 4.57337 5.47262 4.53755 5.91104C4.50078 6.36113 4.5 6.94342 4.5 7.8V16.2C4.5 17.0566 4.50078 17.6389 4.53755 18.089C4.57337 18.5274 4.6383 18.7516 4.71799 18.908C4.90973 19.2843 5.2157 19.5903 5.59202 19.782C5.74842 19.8617 5.97262 19.9266 6.41104 19.9624C6.86113 19.9992 7.44342 20 8.3 20H16.7C17.5566 20 18.1389 19.9992 18.589 19.9624C19.0274 19.9266 19.2516 19.8617 19.408 19.782C19.7843 19.5903 20.0903 19.2843 20.282 18.908C20.3617 18.7516 20.4266 18.5274 20.4624 18.089C20.4992 17.6389 20.5 17.0566 20.5 16.2V14C20.5 13.4477 20.9477 13 21.5 13C22.0523 13 22.5 13.4477 22.5 14V16.2413C22.5 17.0463 22.5 17.7106 22.4558 18.2518C22.4099 18.8139 22.3113 19.3306 22.064 19.816C21.6805 20.5686 21.0686 21.1805 20.316 21.564C19.8306 21.8113 19.3139 21.9099 18.7518 21.9558C18.2106 22 17.5463 22 16.7413 22H8.25868C7.45372 22 6.78936 22 6.24818 21.9558C5.68608 21.9099 5.16937 21.8113 4.68404 21.564C3.93139 21.1805 3.31947 20.5686 2.93598 19.816C2.68868 19.3306 2.59012 18.8139 2.54419 18.2518C2.49998 17.7106 2.49999 17.0463 2.5 16.2413V7.7587C2.49999 6.95374 2.49998 6.28937 2.54419 5.74818C2.59012 5.18608 2.68868 4.66937 2.93598 4.18404C3.31947 3.43139 3.93139 2.81947 4.68404 2.43598C5.16937 2.18868 5.68608 2.09012 6.24818 2.04419C6.78937 1.99998 7.45374 1.99999 8.2587 2Z"
          fill={color}
        />
        <path
          d="M19.0858 7L17.7929 8.29289C17.4024 8.68342 17.4024 9.31658 17.7929 9.70711C18.1834 10.0976 18.8166 10.0976 19.2071 9.70711L22.2071 6.70711C22.5976 6.31658 22.5976 5.68342 22.2071 5.29289L19.2071 2.29289C18.8166 1.90237 18.1834 1.90237 17.7929 2.29289C17.4024 2.68342 17.4024 3.31658 17.7929 3.70711L19.0858 5L18.2587 5C17.4537 4.99999 16.7894 4.99998 16.2482 5.04419C15.6861 5.09012 15.1694 5.18868 14.684 5.43598C13.9314 5.81947 13.3195 6.43139 12.936 7.18404C12.6887 7.66937 12.5901 8.18608 12.5442 8.74818C12.5 9.28936 12.5 9.95372 12.5 10.7587L12.5 12C12.5 12.5523 12.9477 13 13.5 13C14.0523 13 14.5 12.5523 14.5 12V10.8C14.5 9.94342 14.5008 9.36113 14.5376 8.91104C14.5734 8.47262 14.6383 8.24842 14.718 8.09202C14.9097 7.7157 15.2157 7.40974 15.592 7.21799C15.7484 7.1383 15.9726 7.07337 16.411 7.03755C16.8611 7.00078 17.4434 7 18.3 7H19.0858Z"
          fill={color}
        />
      </g>
    </g>
  </svg>
);

export default ShareIcon;
